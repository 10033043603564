<template>
	<div>

    <div class="vue-xlsx-container" @drop.prevent="addFileXlsx" @dragover.prevent>
      <div v-if="checkIsImport()">
        <vs-chip class="ma-2 p-3" color="#28C76F" text-color="white">
          {{ rawFile.name }}
        </vs-chip>
      </div>
			<button type="button" class="xlsx-button mt-2" @click="handleUploadBtnClick">
				<slot></slot>
			</button>
			<input :id="uploadInputId" type="file" class="c-hide" :accept="accept" @change="handkeFileChange" name="input-import-xls-csv">
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import XLSX from 'xlsx';

export default {
	name: 'vue-xlsx',
		data() {
			return {
				rawFile: null,
				workbook: null,
				tableData: {
					header: [],
					body: []
				},
				uploadInputId: (new Date().getUTCMilliseconds()),
				isImport: 0,
			}
		},
		props: {
			accept: {
				type: String,
				default: '.xlsx, .xls, .csv',
			},
			isShowFileXlsx: {
				type: Boolean,
			},
		},
		computed: {
			rABS() {
				return window.xlsxEventBus.XLSX_EVENTS_DATA.options.rABS
			}
		},
		methods: {
			checkIsImport() {
				if (!this.isShowFileXlsx) {
					this.rawFile = null;
				}
				return this.rawFile && this.isShowFileXlsx
			},
			addFileXlsx(e) {
				const droppedFiles = e.dataTransfer.files;
				this.rawFile = droppedFiles[0];
				this.fileConvertToWorkbook(this.rawFile)
					.then((workbook) => {
						let xlsxArr = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
						this.workbook = workbook
						this.initTable(
							this.xlsxArrToTableArr(xlsxArr)
						)
					})
					.catch((err) => {
						console.error(err)
					})
			},
			openLoadingContained(){
					this.$vs.loading({
						scale: 0.45
					})
			},
			handkeFileChange(e) {
				this.$vs.loading({ type: 'point' });
				this.rawFile = e.target.files[0]
				this.fileConvertToWorkbook(this.rawFile)
					.then((workbook) => {
						let xlsxArr = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
						this.workbook = workbook
						this.initTable(
							this.xlsxArrToTableArr(xlsxArr),
						)
						this.$vs.loading.close();
					})
					.catch(() => {})
			},
			fileConvertToWorkbook(file) {
				let reader = new FileReader()
				let fixdata = (data) => {
					let o = "",
						l = 0,
						w = 10240
					for (; l < data.byteLength / w; ++l) {
						o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)))
					}
					o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
					return o
				}

				return new Promise((resolve, reject) => {
					try {
						reader.onload = (renderEvent) => {
							let data = renderEvent.target.result
							if (this.rABS) {
								/* if binary string, read with type 'binary' */
								resolve(XLSX.read(data, {
									type: 'binary'
								}))
							} else {
								/* if array buffer, convert to base64 */
								let arr = fixdata(data)
								resolve(XLSX.read(btoa(arr), {
									type: 'base64'
								}))
							}
						}
						reader.onerror = (error) => {
							reject(error)
						}
						if (this.rABS) {
							reader.readAsBinaryString(file)
						} else {
							reader.readAsArrayBuffer(file)
						}
					} catch (error) {
						reject(error)
					}
				})
			this.isImport = true;

			},
			// custom - maulana yusup abdullah
			checkIfHeaderExist(headers, header) {
				let isExist = false;
				for (let i = 0; i < headers.length; i++) {
					const curr = headers[i];
					if (curr === header) {
						isExist = true;
					}
				};
				return isExist;
			},
			// custom - maulana yusup abdullah
			pushToHeaders(headers, rowHeaders) {
				let newHeaders = headers;
				if (newHeaders.length === 0) {
					newHeaders = rowHeaders;
					return newHeaders;
				};
				for (let i = 0; i < rowHeaders.length; i++) {
					const rowHead = rowHeaders[i];
					for (let j = 0; j < newHeaders.length; j++) {
						const currHead = newHeaders[j];
						const isExist = this.checkIfHeaderExist(newHeaders, rowHead);
						if (!isExist) {
							newHeaders.push(rowHead);
							break;
						};
					};
				};
				return newHeaders;
			},
			xlsxArrToTableArr(xlsxArr) {
				let tableArr = [];
				let length = 0;
				let maxLength = 0;
				let maxLengthIndex = 0;

				// custom - maulana yusup abdullah
				let headers = [];
				xlsxArr.forEach((item, index) => {
					const rowHeaders = Object.keys(item);
					headers = this.pushToHeaders(headers, rowHeaders);
				})

				// custom - maulana yusup abdullah
				xlsxArr.forEach((item, index) => {
					// length = Object.keys(item).length
					length = headers.length
					if (maxLength < length) {
						maxLength = length
						maxLengthIndex = index
					}
				})

				// custom - maulana yusup abdullah
				// let tableHeader = Object.keys(xlsxArr[maxLengthIndex])
				let tableHeader = headers;
				let rowItem = {}
				xlsxArr.forEach((item) => {
					rowItem = {}
					for (let i = 0; i < maxLength; i++) {
						rowItem[tableHeader[i]] = item[tableHeader[i]] || ''
					}
					tableArr.push(rowItem)
				})
				return {
					header: tableHeader,
					data: tableArr
				}
			},
			tableArrToXlsxArr({
				data,
				header
			}) {
				let xlsxArr = []
				let tempObj = {}
				data.forEach((rowItem) => {
					tempObj = {}
					rowItem.forEach((item, index) => {
						tempObj[header[index]] = item
					})
					xlsxArr.push(tempObj)
				})
				return xlsxArr
			},
			initTable({
				data,
				header
			}) {
				this.tableData.header = header
				this.tableData.body = data
				this.$emit('on-select-file', this.tableData)
			},
			handleUploadBtnClick() {
				this.clearAllData()
				document.getElementById(this.uploadInputId).click()
			},
			clearAllData() {
				document.getElementById(this.uploadInputId).value = null
				this.tableData = {
					header: [],
					body: []
				}
				this.rawFile = null
				this.workbook = null
			}
		}
	}
</script>

<style lang="scss">
.vue-xlsx-container {
	display: flex;
  flex-direction: column; /* Display the divs in a column */
  align-items: center; /* Horizontally center the divs */
  width: 100%;
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	.c-hide {
		display: none;
	}
}

.xlsx-button {
	display: inline-block;
	line-height: 1;
	white-space: nowrap;
	cursor: pointer;
	border: 1px solid #0154C7;
	-webkit-appearance: none;
	text-align: center;
	box-sizing: border-box;
	outline: 0;
	margin: 0;
	padding: 10px 28px 10px 28px;
	font-size: 12px;
	border-radius: 4px;
	color: #fff;
	background-color: #0154C7;

	&.button-large {
		padding: 10px 15px;
		font-size: 14px;
	}

	&.button-primary {
		color: #1f2d3d;
		border: 1px solid #bfcbd9;
		background-color: #fff;
	}
}
</style>
